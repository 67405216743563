import * as React from "react";

import Layout from "../../components/Layout";
import OurSchoolRoll from "../../components/OurSchoolRoll";
import image from "../../img/j-victoria-college-front-name-board.jpg"

export default class OurSchoolPage extends React.Component {
  render() {
    return (
      <Layout
        pageTitle="Our School"
        pageDescription="Information about the J/Victoria College, Chulipuram, Sri Lanka."
        pageSlug="/our-school"
      >
        <div
          className="full-width-image-container margin-top-0"
          style={{
            backgroundImage: `url('${image}')`,
          }}
        >
          <h1
            className="has-text-weight-bold is-size-1"
            style={{
              boxShadow: "0.5rem 0 0 #f40, -0.5rem 0 0 #f40",
              backgroundColor: "#f40",
              color: "white",
              padding: "1rem",
            }}
          >
            Our School
          </h1>
        </div>
        <section className="section">
          <div className="container">
            <div className="content">
              <div className="box">
                <p>
                  In the wake of religious reawakening carried by Srila Sri Arumuga Navalar in the second half of the 19th century, 
                  an action group was created in the Jaffna District for the setting up of English medium schools in various parts of Jaffna. 
                  As one of the first steps in this direction, Canagaratnam Muthaliyar started Chulipuram Hindu English School in 1876. 
                  This school survived and grew to be the Victoria College as it is presently known.
                </p>
              </div>
              <OurSchoolRoll />
            </div>
          </div>
        </section>
      </Layout>
    );
  }
}
